<template>
	<div class="subtitle">
		<span
			class="tag"
			@click="selectTag(item, v)"
			v-for="v in getTags(item)"
			:key="v"
			:class="{ '-active': item.variant == v }"
		>
			{{ v }}
		</span>
	</div>
</template>

<script>
export default {
	props: {
		item: Object
	},
	methods: {
		getTags(item) {
			return item.tags || []
		},
		selectTag(item, tag) {
			item = Object.assign(item, {
				variant: tag
			})
		}
	}
}
</script>

<style lang="scss">
.subtitle {
	color: $text-muted;
	margin-top: 0.5rem;
	.tag {
		border: solid 1px;
		padding: 0.4rem;
		cursor: pointer;

		+ .tag {
			margin-left: 0.2rem;
		}

		&.-active {
			background-color: $primary;
			color: #fff;
			border-color: $primary;
		}

		&:hover {
			border-color: $primary;
		}
	}
}
</style>
