<template>
	<div class="box-btn-cat d-block d-md-none">
		<b-button size="sm" v-b-toggle.sidebar-1>
			<i class="la la-filter"></i>
			<span>Categorias</span>
		</b-button>
		<b-sidebar id="sidebar-1" title="Categorias" shadow backdrop>
			<base-sidebar />
		</b-sidebar>
	</div>
</template>

<script>
import BaseSidebar from './BaseSidebar.vue'
export default {
	components: { BaseSidebar }
}
</script>

<style lang="scss">
.box-btn-cat {
	text-align: center;
	margin-bottom: 4rem;
}
</style>
