import ApiService from './ApiService'

export default {
  get() {
    const api = new ApiService()
    return api.get('/categories', {
      limit: 999
    })
  },
  getOne(id) {
    const api = new ApiService()
    return api.get(`/categories/${id}`)
  }
}
