<template>
	<div class="sidebar">
		<b-list-group>
			<b-list-group-item :to="{ name: 'product.list', query: { cat: item.id } }" v-for="item in rows" :key="item.id">
				{{ item.name }}
			</b-list-group-item>
		</b-list-group>
	</div>
</template>

<script>
import CategoryService from '@/services/CategoryService.js'
export default {
	data() {
		return {
			rows: []
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		async init() {
			const data = await CategoryService.get()
			this.rows = data.data
		}
	}
}
</script>

<style lang="scss">
.b-sidebar {
	background-color: #f3f3f4;
	.b-sidebar-header {
		font-size: 2rem;

		.close {
			font-size: 3rem;
		}
	}

	.sidebar .list-group {
		background: none;
	}
}
.sidebar {
	margin-right: 3rem;
	min-width: 22rem;

	.list-group {
		background: #f3f3f4;
		border-radius: 0;
		padding: 0 1rem;

		.list-group-item {
			border: none;
			border-bottom: solid 1px transparentize(#cfcfd0, 0.6);
			background: none;
			padding: 1.2rem 2rem;
			line-height: 1;

			&:hover {
				color: $primary;
			}

			&:last-child {
				border: none;
			}

			&.active {
				background: $primary;
			}
		}
	}

	.b-sidebar-body & {
		margin-right: 0;
	}
}
</style>
